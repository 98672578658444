import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import LocationNav from "../components/locationNav";
import StoryExcerptList from "../components/storyExcerptList";

function storyListTemplate({ data, pageContext }) {
  const { allContentfulStory } = data;
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const prevPageUrl = prevPage > 1 ? `/stories/page=${prevPage}` : "/stories";

  const stories = allContentfulStory.edges;

  return (
    <Layout>
      <LocationNav />
      <StoryExcerptList stories={stories} />
      <nav className="flex justify-between mt-8">
        {!isFirst && (
          <Link to={prevPageUrl} rel="prev" className="text-sm">
            {"<<"} Prev Page
          </Link>
        )}
        {!isLast && (
          <Link to={`/stories/page=${nextPage}`} rel="next" className="text-sm">
            Next Page {">>"}
          </Link>
        )}
      </nav>
    </Layout>
  );
}

export default storyListTemplate;

export const pageQuery = graphql`
  query storyListQuery($skip: Int!, $limit: Int!) {
    allContentfulStory(limit: $limit, skip: $skip) {
      edges {
        node {
          id
          excerpt
          countryFull
          stateFull
        }
      }
    }
  }
`;
